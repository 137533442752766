import { PricingDetails } from "@db-models/pricing-db.model";

export const DASHBOARD_PRICING_JSON: PricingDetails[] = [
  {
    chf: {
      yearly: {
        original_price: 24.90,
      },
      monthly: {
        original_price: 29.90,
      }
    },
    eur: {
      yearly: {
        original_price: 24.90,
      },
      monthly: {
        original_price: 29.90,
      }
    },
    description: "Calenso basic",
    id: 103,
    included_bookings: 0,
    name: "basic",
    min_workers: 1,
    min_store: 1,
    min_group_appointment: 1,
    min_conference: 0,
    subscription_title: 'Basic',
    subscription_subtitle: {
      en_US: "The perfect starting point for GDPR-compalint online appointment scheduling.",
      de_CH: "Der perfekte Ausgangspunkt für die DSGVO-konforme Online-Terminplanung."
    },
    feature_list_title: {
      en_US: "Basis for scheduling your team",
      de_CH: "Basis für die Terminvereinbarung"
    },
    feature_list: {
      dashboard: [
        {
          en_US: "1 user",
          de_CH: "1 Benutzer"
        },
        {
          en_US: "1 store",
          de_CH: "1 Filiale"
        },
        {
          en_US: "GDPR & nDSG as standard",
          de_CH: "DSGVO & nDSG als Standard"
        },
        {
          en_US: "ISO 27001-certified servers",
          de_CH: "ISO 27001-zertifizierte Server"
        },
        {
          en_US: "BSI-certified firewall",
          de_CH: "BSI-zertifizierte Firewall"
        },
        {
          en_US: "Individual appointment scheduling",
          de_CH: "Einzelterminbuchung"
        },
        {
          en_US: "Round Robin",
          de_CH: "Round Robin"
        },
        {
          en_US: "Individual forms",
          de_CH: "Individuelle Formulare"
        },
        {
          en_US: "Website integration",
          de_CH: "Webseiten Integration"
        },
        {
          en_US: "Appointment reminders",
          de_CH: "Terminerinnerungen"
        },
        {
          en_US: "Self-service support",
          de_CH: "Self-Service-Support"
        },
      ]
    },
  },
  {
    chf: {
      yearly: {
        original_price: 74.90,
      },
      monthly: {
        original_price: 89.90,
      }
    },
    eur: {
      yearly: {
        original_price: 74.90,
      },
      monthly: {
        original_price: 89.90,
      }
    },
    description: "Calenso professional",
    id: 102,
    included_bookings: 0,
    name: "professional",
    min_workers: 3,
    min_store: 3,
    min_group_appointment: 10,
    min_conference: 0,
    subscription_title: 'Professional',
    subscription_subtitle: {
      en_US: "Ideal for businesses seeking personalized branding and dependable data protection.",
      de_CH: "Ideal für Unternehmen, die personalisierte Markenbildung und zuverlässigen Datenschutz suchen."
    },
    feature_list_title: {
      en_US: "Everything in Basic, plus",
      de_CH: "Alles in Basic, plus"
    },
    feature_list: {
      dashboard: [
        {
          en_US: "3 users",
          de_CH: "3 Benutzer"
        },
        {
          en_US: "3 stores",
          de_CH: "3 Filialen"
        },
        {
          en_US: "10 group appointments",
          de_CH: "10 Gruppentermine"
        },
        {
          en_US: "Custom logo",
          de_CH: "Benutzerdefiniertes Logo"
        },
        {
          en_US: "Video Meetings",
          de_CH: "Video Beratung"
        },
        {
          en_US: "Branches & team structure",
          de_CH: "Filialen & Team-Struktur"
        },
        {
          en_US: "Analyzes and statistics",
          de_CH: "Analysen und Statistiken"
        },
        {
          en_US: "Standard Support via email (72h)",
          de_CH: "Standard Support via E-Mail (72 Std.)"
        }
      ]
    },
  },
  {
    chf: {
      yearly: {
        original_price: 199.90,
      },
      monthly: {
        original_price: 249.90,
      }
    },
    eur: {
      yearly: {
        original_price: 199.90,
      },
      monthly: {
        original_price: 249.90,
      }
    },
    description: "Calenso Business",
    id: 104,
    included_bookings: 0,
    name: "business",
    min_workers: 5,
    min_store: 5,
    min_group_appointment: 15,
    min_conference: 5,
    subscription_title: 'Business',
    subscription_subtitle: {
      en_US: "Tailored for companies aiming to digitize their appointment processes with a focus on reliable data protection and unique branding.",
      de_CH: "Zugeschnitten für Unternehmen, die ihre Terminprozesse digitalisieren möchten, mit Schwerpunkt auf zuverlässigem Datenschutz und einzigartigem Branding."
    },
    feature_list_title: {
      en_US: "Everything in Professional, plus",
      de_CH: "Alles in Professional, plus"
    },
    feature_list: {
      dashboard: [
        {
          en_US: "5 users",
          de_CH: "5 Benutzer"
        },
        {
          en_US: "5 stores",
          de_CH: "5 Filialen"
        },
        {
          en_US: "5 conference appointments",
          de_CH: "5 Konferenztermine"
        },
        {
          en_US: "15 group appointments",
          de_CH: "15 Gruppentermine"
        },
        {
          en_US: "Multilingualism",
          de_CH: "Mehrsprachigkeit"
        },
        {
          en_US: "Individual design & branding",
          de_CH: "Individuelles Design & Branding"
        },
        {
          en_US: "Qualification questions (routing)",
          de_CH: "Qualifizierungsfragen (Routing)"
        },
        {
          en_US: "Smart-Actions (Workflows)",
          de_CH: "Smart-Actions (Workflows)"
        },
        {
          en_US: "Encrypted customer and appointment data",
          de_CH: "Verschlüsselte Kunden- und Termindaten"
        },
        {
          en_US: "Priority email support with callback service (24h)",
          de_CH: "Priorisierter Support via E-Mail mit Rückrufservice (innerhalb 24 Std.)"
        },
        {
          en_US: 'Mail sending from your own SMTP mail server',
          de_CH: 'Mailversand von eigenem SMTP-Mailserver'
        }
      ]
    }
  },
  {
    chf: {
      yearly: {
        original_price: 1499.90,
      },
      monthly: {
        original_price: 1599.90,
      }
    },
    eur: {
      yearly: {
        original_price: 1499.90,
      },
      monthly: {
        original_price: 1599.90,
      }
    },
    description: "Calenso Enterprise",
    id: 105,
    included_bookings: 0,
    name: "enterprise",
    min_workers: 25,
    min_store: 10,
    min_group_appointment: 30,
    min_conference: 10,
    subscription_title: 'Enterprise',
    subscription_subtitle: {
      en_US: "Suited for companies requiring secure, scalable, and customizable software solutions for digitizing their appointment processes.",
      de_CH: "Geeignet für Unternehmen, die sichere, skalierbare und anpassbare Softwarelösungen für die Digitalisierung ihrer Terminprozesse benötigen."
    },
    feature_list_title: {
      en_US: "Everything in Business, plus",
      de_CH: "Alles in Business, plus"
    },
    feature_list: {
      dashboard: [
        {
          en_US: "25 users",
          de_CH: "25 Benutzer"
        },
        {
          en_US: "10 stores",
          de_CH: "10 Filialen"
        },
        {
          en_US: "10 conference appointments",
          de_CH: "10 Konferenztermine"
        },
        {
          en_US: "30 group appointments",
          de_CH: "30 Gruppentermine"
        },
        {
          en_US: "Corporate design & customization",
          de_CH: "Corporate Design & Branding"
        },
        {
          en_US: "Personal contact person (prioritized support according to SLA)",
          de_CH: "Persönlicher Ansprechspartner (priorisierter Support nach SLA)"
        },
        {
          en_US: "Company-specific user roles Advanced security settings",
          de_CH: "Erweiterte Sicherheitseinstellungen"
        },
        {
          en_US: "Audit Logs",
          de_CH: "Audit Logs"
        },
        {
          en_US: "Custom roles and permissions",
          de_CH: "Benutzerdefinierte Rollen und Berechtigungen"
        },
        {
          en_US: "Single-Sign-on (SSO)",
          de_CH: "Single-Sign-on (SSO)"
        },
        {
          en_US: "CRM and ERP integrations",
          de_CH: "CRM- und ERP Integrationen"
        },
        {
          en_US: "Dynamic Availabilities (via Office365 or Microsoft Exchange Calendar)",
          de_CH: "Dynamische Verfügbarkeiten (via Office365 oder Microsoft Exchange Kalender)"
        }
      ]
    },
  }
]

export const MAX_LIMITE = 100000;
export const MIN_LIMITE = 1;
export const MIN_CONFERENCE_LIMITE = 0;

export const EXTRA_USER_COST = 5;
export const EXTRA_GROUP_APPOINTMENT_COST = 10;
export const EXTRA_STORE_COST = 10;
export const EXTRA_CONFERENCE_COST = 10;

export const SALES_TOKEN = '82e80dc8-9318-4be5-9be1-e8ef79859fca';
