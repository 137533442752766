export const en_US = {
  pricing_table: {
    year: "Year",
    month: "Month",
    get_start_for_free: "Get started for free",
    upon_request: "Upon request",
    get_a_quote: "Book a Demo",
    monthly: "Monthly",
    annual: "Annual",
    year_2: "2 Year",
    year_3: "3 Year",
    save_20: "Save 20%",
    save_25: "Save 25%",
    save_30: "Save 30%",
    change_your_subscription: "Switch to yearly",
    request_now: "Request now",
    upgrade_now: "Upgrade now",
    user_profile: "User profile",
    users_profile: "User profiles",
    manage_user_profile: "Configure your plan",
    change_number_of_user: "Users",
    user_choose_number: "Choose the number of user you need",
    change_number_of_group_appointment: "Group appointments",
    group_appointment_choose_number: "Choose the number of group appointment you need",
    change_number_of_store: "Stores",
    store_choose_number: "Choose the number of store you need",
    change_number_of_conference: "Conferences",
    conference_choose_number: "Choose the number of conference you need",
    per_user_calender: "User",
    per_user_calenders: "Users",
    per_group_appointment: "Group appointment",
    per_group_appointments: "Group appointments",
    per_store: "Store",
    per_stores: "Stores",
    per_conference: "Conference",
    per_conferences: "Conferences",
    cancel: "Cancel",
    reset: "Reset",
    apply: "Apply",
    confirm: "Confirm",
    price_on_request: "Price on request",
    charged_monthly: "charged monthly",
    charged_annualy: "charged annualy",
    extra_user_charge: "for each additional user",
    best_value: "Best value",
    active: "Active",
    compare_all_features: "Compare all features",
    total: "Total",
    close: "Close",
    calenso_pricing: "Calenso Price Calculator",
    rights_reserved: "All rights reserved, <a href='https://www.calenso.com' target='_blank'>Calenso</a> 2024."
  }
};
