export const de_CH = {
  pricing_table: {
    year: "Jahr",
    month: "Monat",
    get_start_for_free: "Jetzt loslegen",
    upon_request: "Auf Anfrage",
    get_a_quote: "Demo anfordern",
    monthly: "Monatlich",
    annual: "Jährlich",
    year_2: "2 Jahre",
    year_3: "3 Jahre",
    save_20: "20 % sparen",
    save_25: "25 % sparen",
    save_30: "30 % sparen",
    change_your_subscription: "Wechseln zu Jahresabo",
    request_now: "Jetzt anfragen",
    upgrade_now: "Jetzt upgraden",
    user_profile: "aktiver Benutzer",
    users_profile: "aktive Benutzer",
    manage_user_profile: "Konfigurieren Sie Ihren Plan",
    change_number_of_user: "Benutzer bzw. Kalender",
    user_choose_number: "Benötigte Anzahl Benutzer auswählen",
    change_number_of_group_appointment: "Gruppentermine",
    group_appointment_choose_number: "Benötigte Anzahl Gruppentermine auswählen",
    change_number_of_store: "Filialen",
    store_choose_number: "Benötigte Anzahl Filialen auswählen",
    change_number_of_conference: "Konferenzen",
    conference_choose_number: "Benötigte Anzahl Konferenztermine auswählen",
    per_user_calender: "Benutzer",
    per_user_calenders: "Benutzer",
    per_group_appointment: "Gruppentermin",
    per_group_appointments: "Gruppentermine",
    per_store: "Filiale",
    per_stores: "Filialen",
    per_conference: "Konferenz",
    per_conferences: "Konferenzen",
    cancel: "Stornieren",
    reset: "Zurücksetzen",
    apply: "Anwenden",
    confirm: "Bestätigen",
    price_on_request: "Preis auf Anfrage",
    charged_monthly: "monatlich abgerechnet",
    charged_annualy: "jährlich abgerechnet",
    extra_user_charge: "für jeden weiteren Benutzer",
    best_value: "Am beliebtesten",
    active: "Aktiv",
    compare_all_features: "Alle Funktionen vergleichen",
    total: "Total",
    close: "Schliessen",
    calenso_pricing: "Calenso Preisrechner",
    rights_reserved: "Alle Rechte vorbehalten, <a href='https://www.calenso.com' target='_blank'>Calenso</a> 2024."
  }
};
